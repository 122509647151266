<template>
  <div class="examResult">
    <div class="examResult_box margin-auto">
      <div class="business_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">微课堂 </el-breadcrumb-item>
          <el-breadcrumb-item>考试结果</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="examResult_main b-c-f">
        <div class="examResult_head center flex-direction">
          <span>考试结果</span>
          <img v-if="result.status == 0" src="../../assets/image/microlecture/not-qualified.png" alt="">
          <img v-else src="../../assets/image/microlecture/qualified.png" alt="">
          <span>{{ result.status == 0 ? '考试未合格' : '恭喜您通过考试' }}</span>
        </div>
        <ul class="examResult_list flex">
          <li class="flex-direction">
            <span>{{ result.fen }}</span>
            <span>得分</span>
          </li>
          <li class="flex-direction">
            <span>{{ result.time }}</span>
            <span>用时（分钟）</span>
          </li>
          <li class="flex-direction">
            <span>{{ result.cuo_ti }}</span>
            <span>错题</span>
          </li>
        </ul>
        <div class="examResult_btn center margin-auto" @click="goAnalysis">
          <img src="../../assets/image/microlecture/wendang.png" alt="">
          <span>查看试卷解析</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      from:{
        id:'',
        answer:'',
        time:''
      },
      result:{},//结果
    }
  },
  methods:{
    //去解析
    goAnalysis () {
      this.$router.replace({path:'/analysis',query:{id:this.result.id}})
    },
    getResult () {
      this.$axios.kaoShiOrder(this.from).then(res=>{
        this.result = res.data
      })
    }
  },
  created () {
    this.from = this.$route.query
    this.getResult() 
  }
}
</script>

<style lang="less" scoped>
.business_tag {
  padding-left: 100px;
}
.examResult_main {
  padding: 80px 320px;
  .examResult_head {
    img {
      width: 250px;
      height: 170px;
      margin: 57px 0 18px 0;
    }
    span {
      color: #3273F6;
      font-size: 36px;
      font-weight: bold;
      &:last-child {
        color: #FC261A;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .examResult_list {
    height: 140px;
    margin: 60px 0;
    padding: 0 132px;
    background: #DFEAFF;
    li {
      display: flex;
      align-items: center;
      span {
        color: #333333;
        font-size: 20px;
        &:last-child {
          color: #999999;
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }
  }
  .examResult_btn {
    width: 320px;
    height: 64px;
    cursor: pointer;
    background: #FFA423;
    border-radius: 32px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }
    span {
      color: #FFFFFF;
      font-size: 24px;
    }
  }
}
.examResult_box {
  width: 1400px;
}
</style>